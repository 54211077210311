p.infos {
    strong {
        display: inline-block;
        width: 146px;
    }
    margin: 0;
}

.reports {
    .details {
        margin-bottom: var(--m-vert-slim);
    }
    td.notes {
        @media screen {
            div {
                display: none;
            }
        }
        @media print {
            textarea {
                display: none;
            }
        }
    }
}