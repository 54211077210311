html {
    zoom: 100%;
    * {
        box-sizing: border-box;
    }
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: var(--fs-copy);
    @media print {
        font-size: 9pt !important;
    }
}



*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: var(--ff-copy);
    -webkit-font-smoothing: antialiased;
    color: var(--c-copy);
}

.wrap {
    overflow: hidden;
    padding: 0 20px;
    @media (min-width: 375px) {
        padding: 0 30px;
    }
    @media (min-width: 1024px) {
        padding: 0;
    }
    margin: 0 auto;
    position: relative;
}

#app {
    display: flex;
    padding: var(--gap-2x);
    #pages {
        flex-grow: 1;
        &.slim {
            max-width: 720px;
            margin: 0 auto;
        }
        &>section {
            position: fixed;
            left: -24600000;
            &.show {
                position: relative;
                left: 0;
            }
        }
    }
}