.print2cols {
    @media print {
        display: grid;    
        grid-template-columns: 1fr 1fr;
        gap: var(--gap);
        &.reverse {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "right left";
        }
    }
}

form .set .field, .form .set .field {
    flex-shrink: 1;
}