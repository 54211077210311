#dockets,
#sheets {
    .table {
        &>div {
            grid-template-columns: 60px 100px 130px 80px 1fr;
        }
    }
}

#peoplecatsoverview,
#categories {
    .table {
        &>div {
            grid-template-columns: 120px 120px 1fr;
        }
    }
}

#materials,
#locations {
    .table {
        &>div {
            grid-template-columns: 120px 1fr;
        }
    }
}

#people {
    .table {
        &>div {
            grid-template-columns: 120px 1fr 1fr 1fr;
        }
    }
}

#report-20percent {
    .table {
        &>div {
            grid-template-columns: 86px 1fr 120px repeat(6,86px);
        }
    }
}

.table {
    border-bottom: solid 1px var(--c-field);
    &>div {
        &:nth-child(2n)>div {
            background: var(--c-field);
        }
        &:nth-child(2n+1):last-child>div {
            border-bottom: solid 2px var(--c-field-intense);
        }
        &:first-child>div {
            background: var(--c-corporate-light);
            color: white;
            font-weight: bold;
        }
        &.highlight {
            color: var(--c-corporate);
        }
        display: grid;
        gap: 2px;
        margin-bottom: 2px;
        &>div {
            padding: 0.7em;
            p {
                line-height: 1.2;
            }            
        }
        &.head .sorted {
            font-weight: bold;
            text-decoration: underline;
        }
        &.head div.sortable:hover {
            text-decoration: underline;
            cursor: pointer;
        }
        &:not(.head):hover > div {
            cursor: pointer;
            background: var(--c-field-intense);
        }
    }
}

table {
    width: 100%;
    thead {
        background:black;
        color: white;
        th {
            font-weight: bold;
        }
    }
    tr td {
        border-bottom: dotted 1px black;
    }
    td, th {
        vertical-align: top;
        padding: 0.7em;
        line-height: 1.2;
    }
    .checkbox {
        display: block;
        width: 1.2em;
        height: 1.2em;
        border: solid 1px black;
    }
}
