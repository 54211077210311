.w100 {
    width: 100%;
}

.fullscreen {
    padding: 0;
    width: 100vw;
    min-height: 100vh;
}

a.button,
.button {
    @media print {
        display: none;
    }
    font-size: 1rem;
    @media(min-width: 1280px){
        font-size: 1.2rem;
    }
    cursor: pointer;
    display: inline-block;
    padding: 0.6em 1.5em;
    background: var(--c-corporate);
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    border-radius: 24px;
    border: none;
    &:hover {
        background-color: var(--c-copy);
    }
    &.light {
        border: solid 1px var(--c-corporate);
        background: white;
        color: var(--c-copy);
    }
    &.small {
        font-size: 0.86rem;
        @media(min-width: 1280px){
            font-size: 1rem;
        }
    }
    &.mini {
        font-size: 0.7rem;
        @media(min-width: 1280px){
            font-size: 0.86rem;
        }
        padding: 0.2em 1em;
    }
}

.disabled {
    opacity: 0.24;
    cursor: default;
    pointer-events: none;
    &.but-clickable {
        pointer-events: all;
    }
}

h2 {
    display: flex;
    justify-content: space-between;
}

@keyframes waitForApi {
    0% {
        width: 10px;
        height: 10px;
    }
    100% {
        width: 100px;
        height: 100px;
    }
}

.waitForApi {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1986;
    background: rgba(255,255,255,0.5);
    div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: var(--c-corporate);
        border-radius: 200px;
        animation: 860ms alternate infinite waitForApi;
    }
}

.noprint {
    @media print {
        display: none !important;
    }
}

.noscreen {
    @media screen {
        display: none;
    }
}