:root {
    
    // Colours
    --c-copy: #242424;
    --c-corporate: #e33134;
    --c-corporate-light: #d0b1b1;
    --c-corporate-subtle: #f9eaea;
    --c-head: var(--c-corporate);
    --c-field: #fcfaf7;
    --c-field-intense: #f0ebe6;
    
    // Fonts
    --fs-copy: 1rem;
    @media(min-width: 1280px){
        --fs-copy: 1.2rem;
    }
    --fs-small: #{pxtoem(14)};
    --fs-large: #{pxtoem(19)};
    --ff-copy: "Lato",sans-serif;
    --ff-head: "Lato",sans-serif;
    --ff-fixed: sans-serif;
    

    // Widths
    --max-width: calc(100% - 4rem);
    --width-margin: 2rem;
    @media(min-width: 1280px) {
        --max-width: 1184px;
        --width-margin: calc(50% - var(--max-width) * 0.5);
    }
    --article-width: 768px;
    @media(min-width: 1280px) {
        --article-width: 960px;
    }
    --article-width-slim: 960px;

    // Margins
    --m-vert: 106px;
    --m-vert-slim: 58px;
    --gap: #{pxtorem(20)};
    @media print {
        --gap: 1rem;
    }
    // Calcs
    --m-vert-2x: calc(2 * var(--m-vert));
    --gap-half: calc(0.5 * var(--gap));
    --gap-2x: calc(2 * var(--gap));
    --gap-4x: calc(4 * var(--gap));
    --gap-6x: calc(6 * var(--gap));
    // Radius
    --radius: #{pxtorem(20)};
    --radius-small: #{pxtorem(5)};
    // Schatten
    --shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    --shadow-flat: 0px 2px 10px rgba(0, 0, 0, 0.15);
    --inset: inset 0px 2px 12px rgba(255, 255, 255, 0.5), inset 0px 1px 2px rgba(0, 0, 0, 0.15);
    @media print {
        --m-vert: 2rem;
        --m-vert-slim: 1rem;
    }
    }

