#app header {

    @media print {
        display: none;
    }

    display: flex;
    flex-direction: column;
    min-height: calc( 100vh - var(--gap-4x));

    padding: var(--gap-2x) var(--gap-2x) var(--gap-2x) 0;
    margin-right: var(--gap-2x);
    border-right: solid 1px var(--c-corporate);
    max-width: 320px;

    ul,
    li {
        padding: 0;
        margin: 0;
        a {
            color: var(--c-copy);
        }
    }

    &>ul {
        padding: 0;
        cursor: pointer;
        &>li {
            border-top: solid 1px var(--c-corporate-light);
            margin: 0;
            &>span,
            &>a {
                display: block;
                padding: 0.7em 1.5em;
                font-weight: bold;
                text-decoration: none;
            }
            &>span {
                cursor: default;
            }
            &>ul {
                padding-bottom: 0.8em;
                &>li {
                    &>a {
                        font-size: 0.86em;
                        padding: 0.16em 1.5em 0.16em 3rem;
                        display: block;
                    }
                }
            }
        }
        a:hover {
            color: var(--c-corporate);
        }
    }
}