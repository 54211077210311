@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, input, select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: black;
  background: white;
}

ol, ul {
  list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

/*******************************************/
/*
/*  Mixins
*/
.shadow-inset {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
}

:root {
  --c-copy: #242424;
  --c-corporate: #e33134;
  --c-corporate-light: #d0b1b1;
  --c-corporate-subtle: #f9eaea;
  --c-head: var(--c-corporate);
  --c-field: #fcfaf7;
  --c-field-intense: #f0ebe6;
  --fs-copy: 1rem;
  --fs-small: 0.875em;
  --fs-large: 1.1875em;
  --ff-copy: "Lato",sans-serif;
  --ff-head: "Lato",sans-serif;
  --ff-fixed: sans-serif;
  --max-width: calc(100% - 4rem);
  --width-margin: 2rem;
  --article-width: 768px;
  --article-width-slim: 960px;
  --m-vert: 106px;
  --m-vert-slim: 58px;
  --gap: 1.25rem;
  --m-vert-2x: calc(2 * var(--m-vert));
  --gap-half: calc(0.5 * var(--gap));
  --gap-2x: calc(2 * var(--gap));
  --gap-4x: calc(4 * var(--gap));
  --gap-6x: calc(6 * var(--gap));
  --radius: 1.25rem;
  --radius-small: 0.3125rem;
  --shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  --shadow-flat: 0px 2px 10px rgba(0, 0, 0, 0.15);
  --inset: inset 0px 2px 12px rgba(255, 255, 255, 0.5), inset 0px 1px 2px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1280px) {
  :root {
    --fs-copy: 1.2rem;
  }
}
@media (min-width: 1280px) {
  :root {
    --max-width: 1184px;
    --width-margin: calc(50% - var(--max-width) * 0.5);
  }
}
@media (min-width: 1280px) {
  :root {
    --article-width: 960px;
  }
}
@media print {
  :root {
    --gap: 1rem;
  }
}
@media print {
  :root {
    --m-vert: 2rem;
    --m-vert-slim: 1rem;
  }
}

.typography, .content {
  color: var(--c-copy);
}
.typography b, .content b,
.typography strong,
.content strong {
  font-weight: bold;
}
.typography i, .content i,
.typography em,
.content em {
  font-style: italic;
}
.typography blockquote, .content blockquote,
.typography pre,
.content pre {
  padding: 1rem 0;
}
.typography .wp-block-quote, .content .wp-block-quote,
.typography blockquote,
.content blockquote {
  padding: 0;
  font-size: 1.2em;
  margin: 2em initial;
  border: none;
}
.typography .wp-block-quote p:last-child, .content .wp-block-quote p:last-child,
.typography blockquote p:last-child,
.content blockquote p:last-child {
  margin-bottom: 0;
}
.typography pre, .content pre {
  font-family: var(--ff-fixed);
  line-height: 1.5;
  background: black;
  color: white;
}
.typography dd, .content dd,
.typography dt,
.content dt,
.typography ul,
.content ul,
.typography ol,
.content ol,
.typography p,
.content p {
  margin: 0 0 1em 0;
  line-height: 1.7;
}
.typography .small, .content .small {
  font-size: 0.75em;
}
.typography .abstract, .content .abstract {
  font-weight: bold;
}
@media (min-width: 560px) {
  .typography .abstract, .content .abstract {
    font-size: var(--fs-large);
  }
}
.typography p:last-child, .content p:last-child {
  margin-bottom: 0;
}
.typography ul li:not([class]), .content ul li:not([class]) {
  margin-top: 10px;
  padding-left: 20px;
  margin-left: 0;
}
.typography ul li:not([class]):nth-child, .content ul li:not([class]):nth-child {
  margin: 0;
}
.typography ul ul, .content ul ul {
  margin-left: 1em;
}
.typography ul li, .content ul li {
  list-style-type: none;
}
.typography ol li, .content ol li {
  list-style-type: decimal;
  margin-left: 1em;
  margin-bottom: 1em;
}
.typography ul, .content ul,
.typography ol,
.content ol {
  margin: 1em 0;
  line-height: 1.4;
}
.typography caption, .content caption,
.typography .h1,
.content .h1,
.typography h1,
.content h1,
.typography h2,
.content h2,
.typography h3,
.content h3,
.typography h4,
.content h4,
.typography h5,
.content h5 {
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.2;
  font-family: var(--ff-head);
  font-weight: normal;
}
.typography caption a, .content caption a,
.typography .h1 a,
.content .h1 a,
.typography h1 a,
.content h1 a,
.typography h2 a,
.content h2 a,
.typography h3 a,
.content h3 a,
.typography h4 a,
.content h4 a,
.typography h5 a,
.content h5 a {
  text-decoration: none;
  color: var(--c-copy);
}
.typography caption a:hover, .content caption a:hover,
.typography .h1 a:hover,
.content .h1 a:hover,
.typography h1 a:hover,
.content h1 a:hover,
.typography h2 a:hover,
.content h2 a:hover,
.typography h3 a:hover,
.content h3 a:hover,
.typography h4 a:hover,
.content h4 a:hover,
.typography h5 a:hover,
.content h5 a:hover {
  color: black;
}
.typography caption:first-child, .content caption:first-child,
.typography .h1:first-child,
.content .h1:first-child,
.typography h1:first-child,
.content h1:first-child,
.typography h2:first-child,
.content h2:first-child,
.typography h3:first-child,
.content h3:first-child,
.typography h4:first-child,
.content h4:first-child,
.typography h5:first-child,
.content h5:first-child {
  margin-top: 0;
}
.typography .h1, .content .h1, .typography h1, .content h1 {
  font-family: var(--ff-head);
  font-size: 2em;
}
.typography .h1.no-top, .content .h1.no-top, .typography h1.no-top, .content h1.no-top {
  margin-top: 0;
}
.typography .h2, .content .h2, .typography h2, .content h2 {
  font-size: 1em;
  font-weight: bold;
}
@media screen {
  .typography .h2, .content .h2, .typography h2, .content h2 {
    font-size: 1.24em;
  }
}
.typography caption, .content caption,
.typography h3,
.content h3 {
  font-size: 1.3em;
}
@media (min-width: 560px) {
  .typography caption, .content caption,
  .typography h3,
  .content h3 {
    font-size: 1.5em;
  }
}
.typography h4, .content h4,
.typography h5,
.content h5 {
  font-size: 1.2em;
}
.typography h1 + *, .content h1 + *,
.typography h2 + *,
.content h2 + *,
.typography h3 + *,
.content h3 + *,
.typography .coltop + h1,
.content .coltop + h1,
.typography .coltop + h2,
.content .coltop + h2,
.typography .coltop + h3,
.content .coltop + h3,
.typography .coltop + h4,
.content .coltop + h4,
.typography .coltop + h5,
.content .coltop + h5,
.typography a[name=copytop] + h1,
.content a[name=copytop] + h1,
.typography a[name=copytop] + h2,
.content a[name=copytop] + h2,
.typography a[name=copytop] + h3,
.content a[name=copytop] + h3,
.typography a[name=copytop] + h4,
.content a[name=copytop] + h4,
.typography a[name=copytop] + h5,
.content a[name=copytop] + h5 {
  margin-top: 0;
}
.typography a, .content a {
  color: var(--c-corporate);
}
.typography a:hover, .content a:hover {
  color: var(--c-head);
}
.typography a:hover:active, .content a:hover:active {
  color: var(--c-corporate);
}
.typography .wp-caption p, .content .wp-caption p {
  font-size: 0.8em;
  margin: 0.5em 0 1em 0;
}

html {
  zoom: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: var(--fs-copy);
}
html * {
  box-sizing: border-box;
}
@media print {
  html {
    font-size: 9pt !important;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--ff-copy);
  -webkit-font-smoothing: antialiased;
  color: var(--c-copy);
}

.wrap {
  overflow: hidden;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 375px) {
  .wrap {
    padding: 0 30px;
  }
}
@media (min-width: 1024px) {
  .wrap {
    padding: 0;
  }
}

#app {
  display: flex;
  padding: var(--gap-2x);
}
#app #pages {
  flex-grow: 1;
}
#app #pages.slim {
  max-width: 720px;
  margin: 0 auto;
}
#app #pages > section {
  position: fixed;
  left: -24600000;
}
#app #pages > section.show {
  position: relative;
  left: 0;
}

#app header {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--gap-4x));
  padding: var(--gap-2x) var(--gap-2x) var(--gap-2x) 0;
  margin-right: var(--gap-2x);
  border-right: solid 1px var(--c-corporate);
  max-width: 320px;
}
@media print {
  #app header {
    display: none;
  }
}
#app header ul,
#app header li {
  padding: 0;
  margin: 0;
}
#app header ul a,
#app header li a {
  color: var(--c-copy);
}
#app header > ul {
  padding: 0;
  cursor: pointer;
}
#app header > ul > li {
  border-top: solid 1px var(--c-corporate-light);
  margin: 0;
}
#app header > ul > li > span, #app header > ul > li > a {
  display: block;
  padding: 0.7em 1.5em;
  font-weight: bold;
  text-decoration: none;
}
#app header > ul > li > span {
  cursor: default;
}
#app header > ul > li > ul {
  padding-bottom: 0.8em;
}
#app header > ul > li > ul > li > a {
  font-size: 0.86em;
  padding: 0.16em 1.5em 0.16em 3rem;
  display: block;
}
#app header > ul a:hover {
  color: var(--c-corporate);
}

.content:not(.wp-block):first-child {
  margin-top: var(--m-vert);
}
.content ._brlbs-switch-consent, .content > .wp-block-columns, .content > .wrapThis, .content > ul, .content > ol, .content > blockquote, .content > form, .content > p, .content > dd, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6, .content > figure {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1179px) {
  .content ._brlbs-switch-consent, .content > .wp-block-columns, .content > .wrapThis, .content > ul, .content > ol, .content > blockquote, .content > form, .content > p, .content > dd, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6, .content > figure {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.content > ul {
  margin-top: 2em;
  margin-bottom: 2em;
}
.content h2 + ul {
  margin-top: 1em;
}
.content > .wp-block-buttons, .content > .wp-block-columns, .content > .wp-block-group {
  margin-left: auto;
  margin-right: auto;
}
.content > .wp-block-group {
  margin-bottom: 1em;
}
.content li {
  background: none;
  padding-left: 0;
}
.content .wp-block-columns {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.content .wp-block-group__inner-container .wp-block-columns {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.content .wp-block-df-block-df-abstract {
  margin-bottom: 3rem;
}

section h1.header .buttons {
  white-space: nowrap;
}
@media print {
  section h1.header .buttons {
    display: none;
  }
}
section h1.header .button:not(:first-child) {
  margin-left: 1em;
}
section h2 {
  line-height: 1;
  margin-top: var(--gap-2x) !important;
  margin-bottom: var(--gap-2x);
  padding-top: var(--gap);
  border-top: solid 1px var(--c-corporate);
  color: var(--c-corporate);
}
@media print {
  section h2 {
    border-color: var(--c-copy);
    margin-top: var(--gap) !important;
    margin-bottom: var(--gap);
    color: var(--c-copy);
  }
}

#docket .qr {
  margin-top: var(--gap-2x);
  background: var(--c-corporate-light);
  padding: var(--gap);
  display: flex;
}
@media print {
  #docket .qr {
    display: none;
  }
}
#docket .qr .code {
  border: solid 10px white;
  width: 140px;
  flex-shrink: 0;
  margin-right: var(--gap);
}
#docket .qr .note {
  flex-grow: 1;
}
#docket p.mealimg {
  margin: 0 0 0.5em;
}
#docket p.mealimg img {
  width: 2em;
}
@media print {
  #docket p.mealimg img {
    width: 1.24em;
  }
}
#docket p.mealimg + label {
  display: block;
  text-align: center;
  width: 100%;
}
@media screen {
  #docket .room {
    padding: var(--gap);
    border: solid 1px var(--c-corporate-light);
  }
}
@media print {
  #docket .room {
    padding: 0;
    border: none;
  }
  #docket .room:not(:last-child) {
    padding-bottom: var(--gap);
    border-bottom: solid 1px black;
    margin-bottom: var(--gap);
  }
}
#docket .room:not(:last-child)   {
  margin-bottom: var(--gap-2x);
}

.feedback {
  padding: var(--gap);
  border: solid 1px var(--c-corporate);
}
.feedback:not(:first-child) {
  margin-top: var(--m-vert-slim);
}
.feedback > div {
  margin-top: var(--gap);
  padding-top: var(--gap);
  border-top: solid 1px var(--c-corporate);
}
.feedback > div p {
  margin: 0;
}

p.speaker input[type=text] {
  width: 7em;
  margin-right: var(--gap);
}

body.qr {
  width: 21cm !important;
  height: 29.7cm !important;
}
body.qr #app {
  height: 100%;
}
body.qr .qrcodes {
  height: 100%;
}
body.qr .qrcodes > div {
  height: 20%;
  display: flex;
  align-items: center;
}
body.qr .qrcodes > div > div {
  padding: 1cm;
}
body.qr .qrcodes > div > div h1 {
  margin: 0 0 0.5em;
}

p.infos {
  margin: 0;
}
p.infos strong {
  display: inline-block;
  width: 146px;
}

.reports .details {
  margin-bottom: var(--m-vert-slim);
}
@media screen {
  .reports td.notes div {
    display: none;
  }
}
@media print {
  .reports td.notes textarea {
    display: none;
  }
}

.loginetc {
  width: 100%;
  max-width: 400px;
  padding: var(--gap-2x);
}

form,
.form {
  margin-bottom: var(--m-vert-slim);
}
form .set,
.form .set {
  display: flex;
  align-items: flex-end;
}
form .set:not(:first-child),
.form .set:not(:first-child) {
  margin-top: var(--gap);
}
form .set.v, form .set.vertical,
.form .set.v,
.form .set.vertical {
  flex-direction: column;
}
form .set .field,
.form .set .field {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  align-items: flex-start;
}
form .set .field:not(:first-child),
.form .set .field:not(:first-child) {
  margin-left: var(--gap);
}
form .set .field.labelfront,
.form .set .field.labelfront {
  display: flex;
  align-items: center;
}
form .set .field.labelfront label,
.form .set .field.labelfront label {
  width: 33%;
  margin: 0;
  padding: 0.8em;
  background: #e8d8d9;
  border: solid 2px #e8d8d9;
  font-size: 1em;
  border-radius: var(--radius-small) 0 0 var(--radius-small);
}
form .set .field.labelfront label + input,
.form .set .field.labelfront label + input {
  border-radius: 0 var(--radius-small) var(--radius-small) 0;
}
form .set .field.quarter,
.form .set .field.quarter {
  flex-grow: 0;
  --thisgap: calc(var(--gap) * 3 / 4);
  flex-basis: calc(25% - var(--thisgap));
}
form .set .field.third,
.form .set .field.third {
  flex-grow: 0;
  --thisgapt: calc(var(--gap) * 2 / 3);
  flex-basis: calc(33.3% - var(--thisgapt));
}
form .set .field.fifth,
.form .set .field.fifth {
  flex-grow: 0;
  --thisgapf: calc(var(--gap) * 4 / 5);
  flex-basis: calc(20% - var(--thisgapf));
}
form .set .field .checkboxes,
.form .set .field .checkboxes {
  display: flex;
}
form .set .field .checkbox,
.form .set .field .checkbox {
  display: flex;
  align-items: center;
}
form .set .field .checkbox label,
.form .set .field .checkbox label {
  width: auto;
  margin: 0 0.5em 0 0.2em;
}
form .set .field.checkbox,
.form .set .field.checkbox {
  flex-shrink: 1;
}
form .set .field.checkbox div, form .set .field.checkbox label,
.form .set .field.checkbox div,
.form .set .field.checkbox label {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  line-height: 1.4;
}
form .set .field.checkbox div.single, form .set .field.checkbox label.single,
.form .set .field.checkbox div.single,
.form .set .field.checkbox label.single {
  align-items: center;
}
form .set .field.checkbox div.single span.check, form .set .field.checkbox label.single span.check,
.form .set .field.checkbox div.single span.check,
.form .set .field.checkbox label.single span.check {
  margin-top: 0;
}
form .set .field.checkbox div span.check, form .set .field.checkbox label span.check,
.form .set .field.checkbox div span.check,
.form .set .field.checkbox label span.check {
  flex-shrink: 0;
  display: block;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.2em;
  border-radius: 0.2em;
  border: solid 1px var(--c-corporate);
  margin-right: 1em;
}
@media print {
  form .set .field.checkbox div span.check, form .set .field.checkbox label span.check,
  .form .set .field.checkbox div span.check,
  .form .set .field.checkbox label span.check {
    border-color: black;
  }
}
form .set .field.checkbox div span.check > span, form .set .field.checkbox label span.check > span,
.form .set .field.checkbox div span.check > span,
.form .set .field.checkbox label span.check > span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
}
form .set .field.checkbox div span.check:hover, form .set .field.checkbox label span.check:hover,
.form .set .field.checkbox div span.check:hover,
.form .set .field.checkbox label span.check:hover {
  border-color: var(--c-copy);
}
form .set .field.checkbox div span.check img, form .set .field.checkbox label span.check img,
.form .set .field.checkbox div span.check img,
.form .set .field.checkbox label span.check img {
  display: none;
  width: 100%;
  height: 100%;
}
form .set .field.checkbox div input[type=checkbox]:checked + span.check img, form .set .field.checkbox label input[type=checkbox]:checked + span.check img,
.form .set .field.checkbox div input[type=checkbox]:checked + span.check img,
.form .set .field.checkbox label input[type=checkbox]:checked + span.check img {
  display: block;
}
form .set .field.checkbox div input, form .set .field.checkbox label input,
.form .set .field.checkbox div input,
.form .set .field.checkbox label input {
  width: 100%;
  flex-grow: 1;
}
form .set .field.checkbox div input[type=checkbox], form .set .field.checkbox label input[type=checkbox],
.form .set .field.checkbox div input[type=checkbox],
.form .set .field.checkbox label input[type=checkbox] {
  position: absolute;
  left: -2466666px;
}
form .set .field.checkbox div,
.form .set .field.checkbox div {
  display: flex;
}
form .set .field.checkbox div span.check,
.form .set .field.checkbox div span.check {
  border-color: var(--c-copy);
  opacity: 0.24;
}
form .set .field .euro,
.form .set .field .euro {
  position: relative;
}
form .set .field .euro:before,
.form .set .field .euro:before {
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  content: "€";
  font-weight: bold;
}
form .set .field .euro input,
.form .set .field .euro input {
  padding-left: 2em;
  text-align: right;
}
form .set .field.error label,
.form .set .field.error label {
  color: var(--c-corporate);
}
form .set p.error,
.form .set p.error {
  color: var(--c-corporate);
  margin-top: 0.5em;
  font-size: var(--fs-small);
}
form .submit,
.form .submit {
  position: sticky;
  bottom: 0;
  display: flex;
  padding: var(--gap) 0;
  background: white;
  justify-content: space-between;
  align-items: center;
}
@media print {
  form .submit,
  .form .submit {
    display: none;
  }
}
form label + .set,
.form label + .set {
  margin-top: 0 !important;
}
form label:not(.checkbox),
.form label:not(.checkbox) {
  display: flex;
  justify-content: space-between;
  font-size: var(--fs-small);
  margin-bottom: var(--gap-half);
}
form label:not(.checkbox).inline-flex,
.form label:not(.checkbox).inline-flex {
  justify-content: flex-start;
}
form label:not(.checkbox).inline-flex span,
.form label:not(.checkbox).inline-flex span {
  margin-left: 1em;
}
@media print {
  form label:not(.checkbox),
  .form label:not(.checkbox) {
    font-size: 0.86em;
  }
}
form label:not(.checkbox) span,
.form label:not(.checkbox) span {
  cursor: pointer;
}
form label:not(.checkbox) span.active,
.form label:not(.checkbox) span.active {
  font-weight: bold;
}
form label:not(.checkbox) span + span:not(.noscreen):not(.noprint),
.form label:not(.checkbox) span + span:not(.noscreen):not(.noprint) {
  margin-left: var(--gap);
}
form .ts-control,
form .ts-wrapper,
.form .ts-control,
.form .ts-wrapper {
  background-color: var(--c-field);
}
form .ts-control > input,
form .ts-wrapper > input,
.form .ts-control > input,
.form .ts-wrapper > input {
  min-width: auto;
  font-size: 1rem;
  margin: 0 !important;
  padding: 0.8em;
}
form .ts-control .single .ts-control,
form .ts-wrapper .single .ts-control,
.form .ts-control .single .ts-control,
.form .ts-wrapper .single .ts-control {
  background-color: var(--c-field);
}
form .ts-control.single.input-active .ts-control,
form .ts-wrapper.single.input-active .ts-control,
.form .ts-control.single.input-active .ts-control,
.form .ts-wrapper.single.input-active .ts-control {
  background-color: var(--c-field);
  border: solid 1px var(--c-corporate-light);
}
form .ts-control .ts-dropdown,
form .ts-wrapper .ts-dropdown,
.form .ts-control .ts-dropdown,
.form .ts-wrapper .ts-dropdown {
  font-size: 1em;
}
form .ts-control .ts-dropdown .option,
form .ts-wrapper .ts-dropdown .option,
.form .ts-control .ts-dropdown .option,
.form .ts-wrapper .ts-dropdown .option {
  padding: 0.8em;
}
form .ts-control .ts-dropdown .option:hover,
form .ts-wrapper .ts-dropdown .option:hover,
.form .ts-control .ts-dropdown .option:hover,
.form .ts-wrapper .ts-dropdown .option:hover {
  background: var(--c-corporate-light);
  color: white;
}
form .list,
.form .list {
  height: 15.4em;
  overflow: auto;
  padding: 0 !important;
  min-width: 100%;
}
form .list span,
.form .list span {
  display: block;
  width: 100%;
  padding: 0.3em 0.8em;
}
form .list span:hover,
.form .list span:hover {
  cursor: pointer;
  background: var(--c-corporate-light);
  color: white;
}
form .list span.selected,
.form .list span.selected {
  background: var(--c-corporate);
  color: white;
}
form .list,
form .full .ts-control,
form .checkboxes,
form textarea,
form select,
form .select2-container,
form input[type=email],
form input[type=search],
form input[type=password],
form input[type=email],
form input[type=date],
form input[type=time],
form input[type=text],
.form .list,
.form .full .ts-control,
.form .checkboxes,
.form textarea,
.form select,
.form .select2-container,
.form input[type=email],
.form input[type=search],
.form input[type=password],
.form input[type=email],
.form input[type=date],
.form input[type=time],
.form input[type=text] {
  font-family: var(--ff-copy);
  margin: 0;
  width: 100%;
  padding: 0.4em;
  background-color: var(--c-field);
}
@media screen {
  form .list,
  form .full .ts-control,
  form .checkboxes,
  form textarea,
  form select,
  form .select2-container,
  form input[type=email],
  form input[type=search],
  form input[type=password],
  form input[type=email],
  form input[type=date],
  form input[type=time],
  form input[type=text],
  .form .list,
  .form .full .ts-control,
  .form .checkboxes,
  .form textarea,
  .form select,
  .form .select2-container,
  .form input[type=email],
  .form input[type=search],
  .form input[type=password],
  .form input[type=email],
  .form input[type=date],
  .form input[type=time],
  .form input[type=text] {
    font-size: 1.2em;
    border: solid 1px #e8d8d9;
    background-color: var(--c-field);
    font-size: 1em;
    box-shadow: var(--inset);
    border-radius: var(--radius-small);
    padding: 0.8em;
    border: solid 1px #e8d8d9;
  }
  form .list:focus,
  form .full .ts-control:focus,
  form .checkboxes:focus,
  form textarea:focus,
  form select:focus,
  form .select2-container:focus,
  form input[type=email]:focus,
  form input[type=search]:focus,
  form input[type=password]:focus,
  form input[type=email]:focus,
  form input[type=date]:focus,
  form input[type=time]:focus,
  form input[type=text]:focus,
  .form .list:focus,
  .form .full .ts-control:focus,
  .form .checkboxes:focus,
  .form textarea:focus,
  .form select:focus,
  .form .select2-container:focus,
  .form input[type=email]:focus,
  .form input[type=search]:focus,
  .form input[type=password]:focus,
  .form input[type=email]:focus,
  .form input[type=date]:focus,
  .form input[type=time]:focus,
  .form input[type=text]:focus {
    border: solid 1px var(--c-corporate);
  }
}
@media print {
  form .list,
  form .full .ts-control,
  form .checkboxes,
  form textarea,
  form select,
  form .select2-container,
  form input[type=email],
  form input[type=search],
  form input[type=password],
  form input[type=email],
  form input[type=date],
  form input[type=time],
  form input[type=text],
  .form .list,
  .form .full .ts-control,
  .form .checkboxes,
  .form textarea,
  .form select,
  .form .select2-container,
  .form input[type=email],
  .form input[type=search],
  .form input[type=password],
  .form input[type=email],
  .form input[type=date],
  .form input[type=time],
  .form input[type=text] {
    font-size: 0.86em;
    background: none;
    border: solid 1px rgba(0, 0, 0, 0.3);
    appearance: none;
  }
  form .list::-webkit-calendar-picker-indicator, form .list::-webkit-clear-button, form .list::-webkit-outer-spin-button, form .list::-webkit-inner-spin-button,
  form .full .ts-control::-webkit-calendar-picker-indicator,
  form .full .ts-control::-webkit-clear-button,
  form .full .ts-control::-webkit-outer-spin-button,
  form .full .ts-control::-webkit-inner-spin-button,
  form .checkboxes::-webkit-calendar-picker-indicator,
  form .checkboxes::-webkit-clear-button,
  form .checkboxes::-webkit-outer-spin-button,
  form .checkboxes::-webkit-inner-spin-button,
  form textarea::-webkit-calendar-picker-indicator,
  form textarea::-webkit-clear-button,
  form textarea::-webkit-outer-spin-button,
  form textarea::-webkit-inner-spin-button,
  form select::-webkit-calendar-picker-indicator,
  form select::-webkit-clear-button,
  form select::-webkit-outer-spin-button,
  form select::-webkit-inner-spin-button,
  form .select2-container::-webkit-calendar-picker-indicator,
  form .select2-container::-webkit-clear-button,
  form .select2-container::-webkit-outer-spin-button,
  form .select2-container::-webkit-inner-spin-button,
  form input[type=email]::-webkit-calendar-picker-indicator,
  form input[type=email]::-webkit-clear-button,
  form input[type=email]::-webkit-outer-spin-button,
  form input[type=email]::-webkit-inner-spin-button,
  form input[type=search]::-webkit-calendar-picker-indicator,
  form input[type=search]::-webkit-clear-button,
  form input[type=search]::-webkit-outer-spin-button,
  form input[type=search]::-webkit-inner-spin-button,
  form input[type=password]::-webkit-calendar-picker-indicator,
  form input[type=password]::-webkit-clear-button,
  form input[type=password]::-webkit-outer-spin-button,
  form input[type=password]::-webkit-inner-spin-button,
  form input[type=email]::-webkit-calendar-picker-indicator,
  form input[type=email]::-webkit-clear-button,
  form input[type=email]::-webkit-outer-spin-button,
  form input[type=email]::-webkit-inner-spin-button,
  form input[type=date]::-webkit-calendar-picker-indicator,
  form input[type=date]::-webkit-clear-button,
  form input[type=date]::-webkit-outer-spin-button,
  form input[type=date]::-webkit-inner-spin-button,
  form input[type=time]::-webkit-calendar-picker-indicator,
  form input[type=time]::-webkit-clear-button,
  form input[type=time]::-webkit-outer-spin-button,
  form input[type=time]::-webkit-inner-spin-button,
  form input[type=text]::-webkit-calendar-picker-indicator,
  form input[type=text]::-webkit-clear-button,
  form input[type=text]::-webkit-outer-spin-button,
  form input[type=text]::-webkit-inner-spin-button,
  .form .list::-webkit-calendar-picker-indicator,
  .form .list::-webkit-clear-button,
  .form .list::-webkit-outer-spin-button,
  .form .list::-webkit-inner-spin-button,
  .form .full .ts-control::-webkit-calendar-picker-indicator,
  .form .full .ts-control::-webkit-clear-button,
  .form .full .ts-control::-webkit-outer-spin-button,
  .form .full .ts-control::-webkit-inner-spin-button,
  .form .checkboxes::-webkit-calendar-picker-indicator,
  .form .checkboxes::-webkit-clear-button,
  .form .checkboxes::-webkit-outer-spin-button,
  .form .checkboxes::-webkit-inner-spin-button,
  .form textarea::-webkit-calendar-picker-indicator,
  .form textarea::-webkit-clear-button,
  .form textarea::-webkit-outer-spin-button,
  .form textarea::-webkit-inner-spin-button,
  .form select::-webkit-calendar-picker-indicator,
  .form select::-webkit-clear-button,
  .form select::-webkit-outer-spin-button,
  .form select::-webkit-inner-spin-button,
  .form .select2-container::-webkit-calendar-picker-indicator,
  .form .select2-container::-webkit-clear-button,
  .form .select2-container::-webkit-outer-spin-button,
  .form .select2-container::-webkit-inner-spin-button,
  .form input[type=email]::-webkit-calendar-picker-indicator,
  .form input[type=email]::-webkit-clear-button,
  .form input[type=email]::-webkit-outer-spin-button,
  .form input[type=email]::-webkit-inner-spin-button,
  .form input[type=search]::-webkit-calendar-picker-indicator,
  .form input[type=search]::-webkit-clear-button,
  .form input[type=search]::-webkit-outer-spin-button,
  .form input[type=search]::-webkit-inner-spin-button,
  .form input[type=password]::-webkit-calendar-picker-indicator,
  .form input[type=password]::-webkit-clear-button,
  .form input[type=password]::-webkit-outer-spin-button,
  .form input[type=password]::-webkit-inner-spin-button,
  .form input[type=email]::-webkit-calendar-picker-indicator,
  .form input[type=email]::-webkit-clear-button,
  .form input[type=email]::-webkit-outer-spin-button,
  .form input[type=email]::-webkit-inner-spin-button,
  .form input[type=date]::-webkit-calendar-picker-indicator,
  .form input[type=date]::-webkit-clear-button,
  .form input[type=date]::-webkit-outer-spin-button,
  .form input[type=date]::-webkit-inner-spin-button,
  .form input[type=time]::-webkit-calendar-picker-indicator,
  .form input[type=time]::-webkit-clear-button,
  .form input[type=time]::-webkit-outer-spin-button,
  .form input[type=time]::-webkit-inner-spin-button,
  .form input[type=text]::-webkit-calendar-picker-indicator,
  .form input[type=text]::-webkit-clear-button,
  .form input[type=text]::-webkit-outer-spin-button,
  .form input[type=text]::-webkit-inner-spin-button {
    display: none;
    background: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: 0;
    background-color: red;
  }
}
form .list.error,
form .full .ts-control.error,
form .checkboxes.error,
form textarea.error,
form select.error,
form .select2-container.error,
form input[type=email].error,
form input[type=search].error,
form input[type=password].error,
form input[type=email].error,
form input[type=date].error,
form input[type=time].error,
form input[type=text].error,
.form .list.error,
.form .full .ts-control.error,
.form .checkboxes.error,
.form textarea.error,
.form select.error,
.form .select2-container.error,
.form input[type=email].error,
.form input[type=search].error,
.form input[type=password].error,
.form input[type=email].error,
.form input[type=date].error,
.form input[type=time].error,
.form input[type=text].error {
  border: solid 1px var(--c-corporate);
  background: var(--c-corporate-subtle);
}
form input[type=checkbox],
form input[type=radio],
form input[type=submit],
form input[type=button],
form select,
.form input[type=checkbox],
.form input[type=radio],
.form input[type=submit],
.form input[type=button],
.form select {
  cursor: pointer;
}
form .plus20,
.form .plus20 {
  text-decoration: underline;
  color: var(--c-corporate);
  margin: 0.5em 0;
  cursor: pointer;
}
form .gform_wrapper,
.form .gform_wrapper {
  margin-bottom: var(--m-vert);
}
form .gform_wrapper .gform_fields,
.form .gform_wrapper .gform_fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: var(--gap);
}
form .gform_wrapper .gform_fields > *,
.form .gform_wrapper .gform_fields > * {
  grid-column: span 6;
}
form .gform_wrapper .gform_fields .gfield--width-half,
.form .gform_wrapper .gform_fields .gfield--width-half {
  grid-column: span 3;
}
form .gform_wrapper .gform_fields .gfield--width-third,
.form .gform_wrapper .gform_fields .gfield--width-third {
  grid-column: span 2;
}
form .gform_wrapper .ginput_container,
.form .gform_wrapper .ginput_container {
  margin: 0;
}
form .gform_wrapper .gfield_label,
.form .gform_wrapper .gfield_label {
  margin: 0.5em 0;
}
form .gform_wrapper .gform_fields .gfield,
.form .gform_wrapper .gform_fields .gfield {
  margin: 0 0 1em;
}
form .gform_wrapper .hidden_label label,
.form .gform_wrapper .hidden_label label {
  display: none;
}
form .gform_wrapper .hidden_label input,
.form .gform_wrapper .hidden_label input {
  margin-top: 0;
}

span.error input,
span.error textarea,
span.error select,
div.error input,
div.error textarea,
div.error select {
  border-color: var(--c-corporate) !important;
  background-color: var(--c-corporate-subtle) !important;
  color: var(--c-corporate);
}

#dockets .table > div,
#sheets .table > div {
  grid-template-columns: 60px 100px 130px 80px 1fr;
}

#peoplecatsoverview .table > div,
#categories .table > div {
  grid-template-columns: 120px 120px 1fr;
}

#materials .table > div,
#locations .table > div {
  grid-template-columns: 120px 1fr;
}

#people .table > div {
  grid-template-columns: 120px 1fr 1fr 1fr;
}

#report-20percent .table > div {
  grid-template-columns: 86px 1fr 120px repeat(6, 86px);
}

.table {
  border-bottom: solid 1px var(--c-field);
}
.table > div {
  display: grid;
  gap: 2px;
  margin-bottom: 2px;
}
.table > div:nth-child(2n) > div {
  background: var(--c-field);
}
.table > div:nth-child(2n+1):last-child > div {
  border-bottom: solid 2px var(--c-field-intense);
}
.table > div:first-child > div {
  background: var(--c-corporate-light);
  color: white;
  font-weight: bold;
}
.table > div.highlight {
  color: var(--c-corporate);
}
.table > div > div {
  padding: 0.7em;
}
.table > div > div p {
  line-height: 1.2;
}
.table > div.head .sorted {
  font-weight: bold;
  text-decoration: underline;
}
.table > div.head div.sortable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.table > div:not(.head):hover > div {
  cursor: pointer;
  background: var(--c-field-intense);
}

table {
  width: 100%;
}
table thead {
  background: black;
  color: white;
}
table thead th {
  font-weight: bold;
}
table tr td {
  border-bottom: dotted 1px black;
}
table td, table th {
  vertical-align: top;
  padding: 0.7em;
  line-height: 1.2;
}
table .checkbox {
  display: block;
  width: 1.2em;
  height: 1.2em;
  border: solid 1px black;
}

.w100 {
  width: 100%;
}

.fullscreen {
  padding: 0;
  width: 100vw;
  min-height: 100vh;
}

a.button,
.button {
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  padding: 0.6em 1.5em;
  background: var(--c-corporate);
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  border-radius: 24px;
  border: none;
}
@media print {
  a.button,
  .button {
    display: none;
  }
}
@media (min-width: 1280px) {
  a.button,
  .button {
    font-size: 1.2rem;
  }
}
a.button:hover,
.button:hover {
  background-color: var(--c-copy);
}
a.button.light,
.button.light {
  border: solid 1px var(--c-corporate);
  background: white;
  color: var(--c-copy);
}
a.button.small,
.button.small {
  font-size: 0.86rem;
}
@media (min-width: 1280px) {
  a.button.small,
  .button.small {
    font-size: 1rem;
  }
}
a.button.mini,
.button.mini {
  font-size: 0.7rem;
  padding: 0.2em 1em;
}
@media (min-width: 1280px) {
  a.button.mini,
  .button.mini {
    font-size: 0.86rem;
  }
}

.disabled {
  opacity: 0.24;
  cursor: default;
  pointer-events: none;
}
.disabled.but-clickable {
  pointer-events: all;
}

h2 {
  display: flex;
  justify-content: space-between;
}

@keyframes waitForApi {
  0% {
    width: 10px;
    height: 10px;
  }
  100% {
    width: 100px;
    height: 100px;
  }
}
.waitForApi {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1986;
  background: rgba(255, 255, 255, 0.5);
}
.waitForApi div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--c-corporate);
  border-radius: 200px;
  animation: 860ms alternate infinite waitForApi;
}

@media print {
  .noprint {
    display: none !important;
  }
}

@media screen {
  .noscreen {
    display: none;
  }
}

@media print {
  .print2cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap);
  }
  .print2cols.reverse {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "right left";
  }
}

form .set .field, .form .set .field {
  flex-shrink: 1;
}

footer {
  padding: 2em 0;
  display: flex;
  justify-content: center;
}
footer a {
  color: var(--c-copy);
  text-decoration: none;
}
footer nav ul {
  display: flex;
  justify-content: center;
}
footer nav ul li {
  margin: 0 1em;
}

.pr {
  position: relative;
}
.pr.pa1 {
  width: 100%;
  padding-bottom: 100%;
}
.pr.pa1.wide {
  padding-bottom: 56%;
}

.pa {
  position: absolute;
}
.pa.l0 {
  left: 0;
}
.pa.t0 {
  top: 0;
}
.pa.r0 {
  right: 0;
}
.pa.b0 {
  bottom: 0;
}

.pa1 {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.pa1.of {
  object-fit: cover;
}

.fxc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fxsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fxsb.afs {
  align-items: flex-start;
}
.fxsb.afe {
  align-items: flex-end;
}

.txc {
  text-align: center;
}

.hideMove {
  position: fixed;
  top: -5000px;
}

.clear {
  clear: both;
}

@media screen {
  .printonly {
    display: none;
  }
}

.wp-caption,
img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.blur-50 {
  -webkit-filter: url("#sharpBlur");
  filter: url("#sharpBlur");
}

.hideSvgSoThatItSupportsFirefox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}