.loginetc {
	width: 100%;
	max-width: 400px;
	padding: var(--gap-2x);
}

form,
.form {
	margin-bottom: var(--m-vert-slim);
	&:has(.submit) {
	}
	.set {
		&:not(:first-child) {
			margin-top: var(--gap);
		}
		display: flex;
				align-items: flex-end;
		&.v,
		&.vertical {
			flex-direction: column;
		}
		.field {
			&:not(:first-child) {
				margin-left: var(--gap);
			}
			flex-grow: 1;
			flex-shrink: 0;
			width: 100%;
			align-items: flex-start;
			&.labelfront {
				display: flex;
				align-items: center;
				label {
					width: 33%;
					margin: 0;
					padding: 0.8em;
					background: #e8d8d9;
					border: solid 2px #e8d8d9;
					font-size: 1em;
					border-radius: var(--radius-small) 0 0 var(--radius-small);
					&+input {
						border-radius: 0 var(--radius-small) var(--radius-small) 0;
					}
				}
			}
			&.quarter {
				flex-grow: 0;
				--thisgap: calc(var(--gap) * 3 / 4);
				flex-basis: calc(25% - var(--thisgap));
			}
			&.third {
				flex-grow: 0;
				--thisgapt: calc(var(--gap) * 2 / 3);
				flex-basis: calc(33.3% - var(--thisgapt));
			}
			&.fifth {
				flex-grow: 0;
				--thisgapf: calc(var(--gap) * 4 / 5);
				flex-basis: calc(20% - var(--thisgapf));
			}
			.checkboxes {
				display: flex;
			}
			.checkbox {
				display: flex;
				align-items: center;
				label {
					width: auto;
					margin: 0 0.5em 0 0.2em;
				}
			}
			&.checkbox {
				flex-shrink: 1;
			}
			&.checkbox div,
			&.checkbox label {
				cursor: pointer;
				display: inline-flex;
				align-items: flex-start;
				&.single {
					align-items: center;
					span.check {
						margin-top: 0;
					}
				}
				line-height: 1.4;
				span.check {
					flex-shrink: 0;
					display: block;
					width: 1.2em;
					height: 1.2em;
					margin-top: 0.2em;
					border-radius: 0.2em;
					border: solid 1px var(--c-corporate);
					@media print {
						border-color: black;
					}
					margin-right: 1em;
					&>span {
						display: block;
						width: 100%;
						height: 100%;
						border-radius: 0.2em;
					}
					&:hover {
						border-color: var(--c-copy);
					}
					img {
						display: none;
						width: 100%;
						height: 100%;
					}
				}
				input[type="checkbox"]:checked+span.check {
					img {
						display: block;
					}
				}
				input {
					width: 100%;
					flex-grow: 1;
				}
				input[type="checkbox"] {
					position: absolute;
					left: -2466666px;
				}
			}
			&.checkbox div {
				display: flex;
				span.check {
					border-color: var(--c-copy);
					opacity: 0.24;
				}
			}
			.euro {
				position: relative;
				&:before {
					position: absolute;
					left: 1em;
					top: 50%;
					transform: translateY(-50%);
					content: "€";
					font-weight: bold;
				}
				input {
					padding-left: 2em;
					text-align: right;
				}
			}
			&.error {
				label {
					color: var(--c-corporate);
				}
			}
		}
		p.error {
			color: var(--c-corporate);
			margin-top: 0.5em;
			font-size: var(--fs-small);
		}
	}

	.submit {
		@media print {
            display: none;
        }
		position: sticky;
		bottom: 0;
		display: flex;
		padding: var(--gap) 0;
		background: white;
		justify-content: space-between;
		align-items: center;
	}

	label+.set {
		margin-top: 0 !important;
	}

	label:not(.checkbox) {
		display: flex;
		justify-content: space-between;
		&.inline-flex {
			justify-content: flex-start;
			span {
				margin-left: 1em;
			}
		}
		@media print {
			font-size: 0.86em;
		}
		font-size: var(--fs-small);
		margin-bottom: var(--gap-half);
		span {
			cursor: pointer;
			&.active {
				font-weight: bold;
			}
			&+span:not(.noscreen):not(.noprint) {
				margin-left: var(--gap);
			}
		}
	}

	.ts-control,
	.ts-wrapper {
		background-color: var(--c-field);
		&>input {
			min-width: auto;
			font-size: 1rem;
			margin: 0 !important;
			padding: 0.8em;
		}
		.single {
			.ts-control {
				background-color: var(--c-field);
			}
		}
		&.single.input-active .ts-control {
			background-color: var(--c-field);
			border: solid 1px var(--c-corporate-light);
		}
		.ts-dropdown {
			font-size: 1em;
			.option {
				padding: 0.8em;
				&:hover {
					background: var(--c-corporate-light);
					color: white;
				}
			}
		}
	}

	.list {
		height: calc(11em * 1.4);
		overflow: auto;
		padding: 0 !important;
		min-width: 100%;
		span {
			display: block;
			width: 100%;
			padding: 0.3em 0.8em;
			&:hover {
				cursor: pointer;
				background: var(--c-corporate-light);
				color: white;
			}
			&.selected {
				background: var(--c-corporate);
				color: white;
			}
		}
	}
	.list,
	.full .ts-control,
	.checkboxes,
	textarea,
	select,
	.select2-container ,
	input[type='email'],
	input[type='search'],
	input[type='password'],
	input[type='email'],
	input[type='date'],
	input[type='time'],
	input[type='text'] {
		font-family: var(--ff-copy);
		margin: 0;
		width: 100%;
		padding: 0.4em;
		background-color: var(--c-field);
		@media screen {
			font-size: 1.2em;
			border: solid 1px #e8d8d9;
			background-color: var(--c-field);
			font-size: 1em;
			box-shadow: var(--inset);
			border-radius: var(--radius-small);
			padding: 0.8em;
			border: solid 1px #e8d8d9;
			&:focus {
				border: solid 1px var(--c-corporate);
			}
		}
		@media print {
			&::-webkit-calendar-picker-indicator,
			&::-webkit-clear-button,
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				display: none;
				background: none;
				-webkit-appearance: none;
				margin: 0;
				padding: 0;
				width: 0;
				background-color: red;
			}
			font-size: 0.86em;
			background: none;
			border: solid 1px rgba(0, 0, 0, 0.3);
			appearance: none;
		}
		&.error {
			border: solid 1px var(--c-corporate);
			background: var(--c-corporate-subtle);
		}
	}

	input[type='checkbox'],
	input[type='radio'],
	input[type='submit'],
	input[type='button'],
	select {
		cursor: pointer;
	}

	.plus20 {
		text-decoration: underline;
		color: var(--c-corporate);
		margin: 0.5em 0;
		cursor: pointer;
	}
	.gform_wrapper {
		margin-bottom: var(--m-vert);

		.gform_fields {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			gap: var(--gap);

			&>* {
				grid-column: span 6;
			}

			.gfield--width-half {
				grid-column: span 3;
			}

			.gfield--width-third {
				grid-column: span 2;
			}
		}

		.ginput_container {
			margin: 0;
		}

		.gfield_label {
			margin: 0.5em 0;
		}

		.gform_fields {
			.gfield {
				margin: 0 0 1em;
			}
		}

		.hidden_label {
			label {
				display: none;
			}

			input {
				margin-top: 0;
			}
		}
	}

}

span.error,
div.error {
	input,
	textarea,
	select {
		border-color: var(--c-corporate) !important;
		background-color: var(--c-corporate-subtle) !important;
		color: var(--c-corporate);
	}
}
