footer {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    a {
        color: var(--c-copy);
        text-decoration: none;
    }
    nav {
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0 1em;
            }
        }
    }
}