section {

    h1.header {
        .buttons {
            @media print {
                display: none;
            }
            white-space: nowrap;
        }
        .button:not(:first-child) {
            margin-left: 1em;
        }
    }

    h2 {
        line-height: 1;
        margin-top: var(--gap-2x) !important;
        margin-bottom: var(--gap-2x);
        padding-top: var(--gap);
        border-top: solid 1px var(--c-corporate);
        color: var(--c-corporate);
        @media print {
            border-color: var(--c-copy);
            margin-top: var(--gap) !important;
            margin-bottom: var(--gap);
            color: var(--c-copy);
        }
    }

}

#docket {
    .qr {
        margin-top: var(--gap-2x);
        @media print {
            display: none;
        }
        background: var(--c-corporate-light);
        padding: var(--gap);
        display: flex;
        .code {
            border: solid 10px white;
            width: 140px;
            flex-shrink: 0;
            margin-right: var(--gap);
        }
        .note {
            flex-grow: 1;
        }
    }
    p.mealimg {
        img {
            width: 2em;
            @media print {
                width: 1.24em;
            }
        }
        margin: 0 0 0.5em;
        &+label {
            display: block;
            text-align: center;
            width: 100%;
        }
    }
    .room {
        @media screen {
            padding: var(--gap);
            border: solid 1px var(--c-corporate-light);
        }
        @media print {
            padding: 0;
            border: none;
            &:not(:last-child) {
                padding-bottom: var(--gap);
                border-bottom: solid 1px black;
                margin-bottom: var(--gap);
            }
        }
        &:not(:last-child) {
            margin-bottom: var(--gap-2x);
        }
    }
}

.feedback {
    &:not(:first-child) {
        margin-top: var(--m-vert-slim);
    }
    padding: var(--gap);
    border: solid 1px var(--c-corporate);
    &>div {
        margin-top: var(--gap);
        padding-top: var(--gap);
        border-top: solid 1px var(--c-corporate);
        p {
            margin: 0;
        }
    }
}

p.speaker {
    input[type="text"] {
        width: 7em;
        margin-right: var(--gap);
    }
}

    body.qr {
        width: 21cm !important;
        height: 29.7cm !important;
        #app {
            height: 100%;
        }

.qrcodes {
    height: 100%;
    &>div {
        height: 20%;
        display: flex;
        align-items: center;
        &>div {
            padding: 1cm;
            h1 {
                margin: 0 0 0.5em;
            }
        }
    }
}
    }
